import React, { Component } from 'react';

class AboutText extends Component{

    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area section-space--inner--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="video-cta-content">
                                    <h3 className="video-cta-content__title">Blossom Progressive Montessori</h3>
                                    <h4 className="video-cta-content__small-title">Mission Statement</h4>
                                    <p className="video-cta-content__text">As professionals we are committed to the complete development of the child’s character emotionally, intellectually, physically, socially and morally. Within a stable and caring Montessori environment, our responsibility is to nurture, guide, educate, and prepare the child to become a happy, independent contributing member of a global community. We are equally dedicated to developing and maintaining a mutually respectful partnership with parent/guardians, families and caregivers in the best interest of the child.</p>
                                    <p className="video-cta-content__text">In our capacity as early childhood educators, we strive to respond to the needs of the community. Our goal is to provide access to resources which will strengthen and nurture family and community relationships.</p>
                                    <p className="video-cta-content__text">As childcare professionals, we strongly believe that we make a valuable and unique contribution to the overall growth of the child through our continued commitment to personal and professional development.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 col-md-6 section-space--inner--30">
                                <div className="cta-video-image">
                                    <div className="video-popup">
                                        <div className="cta-video-image__image">
                                            <img src="assets/img/curriculum/single-blossom-532x532.jpg" className="img-fluid" alt="" />
                                        </div>

                                    </div>
                                </div>
                                <div className="video-cta-content section-space--inner--50">
                                    <p className="video-cta-content__text__quote__bold">
                                        <em>Show me,</em> and I’ll remember today,<br />
                                        <em>Teach me,</em> and I’ll remember tomorrow,<br />
                                        <em>Allow me to discover,</em> and I’ll remember<br />
                                        <em>FOREVER!</em>
                                    </p>
                                    <p className="video-cta-content__text__quote-cite">
                                        ~ Dr. Maria Montessori
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default AboutText;