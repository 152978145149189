import React, { Component } from 'react';

class SecretQuote extends Component{

    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area section-space--inner--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="video-cta-content">
                                    <h3 className="video-cta-content__title"><span className="video-cta-content__quote">&ldquo;&nbsp;</span>The Secret of Good Teaching…</h3>
                                    <p className="video-cta-content__text__quote">
                                        …is to regard the child's intelligence as a fertile field in which seeds many be sown, to grow under the heat of flaming imagination. Our aim therefore is not merely to make the child understand, and still less to force him to memorize, but so to touch his imagination as to enthuse him to his inner most core. We do not want complacent pupils, but eager ones: we seek to sow life in the child rather than theories, to help him in his growth, mental and emotional as well as physical, and for that we must offer grand and lofty ideas to the human mind, which we find ever ready to receive them, demanding more and more.<span className="video-cta-content__quote-in-text">&nbsp;&rdquo;</span>
                                    </p>
                                    <p className="video-cta-content__text__quote-cite">
                                        ~ Dr. Maria Montessori - To Educate the Human Potential
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default SecretQuote;