import React, { Component } from 'react';

class OurGoals extends Component{
    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area contrast-bg section-space--inner--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <div className="video-cta-content">
                                    <h3 className="video-cta-content__title">Our Goals</h3>
                                    <p className="video-cta-content__text">Our primary goal is to cultivate a child's desire to learn. Children are driven by a natural curiosity and love for knowledge. Rather than seeing a child as an empty vessel to fill, we see them as eager beings, always craving to develop new skills and understanding. Knowing this, we see ourselves as guides directing this natural urge to learn.</p>
                                    <p className="video-cta-content__text">Through interaction with each child, we encourage and direct their energies. Each child has his/her own unique pace. Some children enjoy working quickly, and yet others like to take their time. Recognizing this, we encourage children to strive for their personal best academically, socially, and artistically.</p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="col-md-6 section-space--bottom--20">
                                <div className="cta-video-image__image">
                                    <img src="assets/img/curriculum/heart-rocks.jpg" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="video-cta-content">
                                    <p className="video-cta-content__text">Not only do we consciously guide the individual child in their self growth, but we also set social standards of behavior within the classroom. These standards govern behavior in any social setting. As a result, the child is offered an opportunity to develop an understanding of what behavior is socially accepted, and which ones are not. Courtesy and respect towards fellow classmates is an important part of our classroom atmosphere.</p>
                                    <p className="video-cta-content__text">The curriculum and environment form a framework from which children can develop their social skills and acquire knowledge at their own pace. The habits and skills a child adopts at Blossom Progressive Montessori are life long. The most important of these are the thirst for knowledge, developing social skills, and striving for one's personal best.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default OurGoals;