import React, {Component} from 'react';

class ProgramsGrid extends Component{
  render(){
    return(
      <div>
        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--60 grey-bg--style2">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Preschool and Jr K Schedule</h3>
                    <ul>
                      <li><strong>8:30-9:15</strong> Outdoor play</li>
                      <li><strong>9:15-9:45</strong> Circle Time <br /><strong> </strong> Presentations/Theme/Phonics</li>
                      <li><strong>9:45-10:00</strong> HWOT Books</li>
                      <li><strong>10:00-10:30</strong> Group Snack</li>
                      <li><strong>10:30-12:00</strong> Montessori Work<br /><strong> </strong> Free Play <br /><strong> </strong> Art/Math Books/Cooking</li>
                      <li><strong>12:00-12:30</strong> Circle Time <br /><strong> </strong> Story/Songs/Music/Language</li>
                    </ul>
                  </div>
                  {
                    <div className="project-information">
                      <h3>Group Childcare Schedule</h3>
                      <ul>
                        <li><strong>12:30-1:00</strong> Lunch</li>
                        <li><strong>1:00-1:30</strong> Quiet Time <br /><strong> </strong>books, puzzles, colouring, nap</li>
                        <li><strong>1:30 - 2:00</strong> Free Play</li>
                        <li><strong>2:00 - 3:00</strong> Outside Play</li>
                      </ul>
                    </div>
                  }
                </div>
                <div className="col-lg-8 col-12 section-space--inner--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className='project-details'>
                    <h2 className='section-title'>PROGRAM OPTIONS<br/><small>30 Months to School Age</small></h2>
                    <h3 className='section-subtitle'>PRESCHOOL AND JR K MONTHLY TUITION FEES</h3>
                    <table>
                      <thead>
                        <tr>
                          <th className='px-2' align='left' valign='top'>{' '}</th>
                          <th className='px-2' align='center' valign='top'>Before Reduction</th>
                          <th className='px-2' align='center' valign='top'>After Reduction</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className='px-2' align='left' valign='top'>2 DAYS Tues/Thurs</th>
                          <td className='px-2' align='center' valign='top'>$250.00</td>
                          <td className='px-2' align='center' valign='top'>$212.00</td>
                        </tr>
                        <tr>
                          <th className='px-2' align='left' valign='top'>3 DAYS Mon/Wed/Fri</th>
                          <td className='px-2' align='center' valign='top'>$350.00</td>
                          <td className='px-2' align='center' valign='top'>$293.00</td>
                        </tr>
                        <tr>
                          <th className='px-2' align='left' valign='top'>5 DAYS Mon-Fri</th>
                          <td className='px-2' align='center' valign='top'>$600.00</td>
                          <td className='px-2' align='center' valign='top'>$505.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <p></p>

                    <h3 className='section-subtitle'>GROUP CHILDCARE MONTHLY TUITION FEES</h3>
                    <table>
                      <thead>
                        <tr>
                          <th className='px-2' align='left' valign='top'>{' '}</th>
                          <th className='px-2' align='center' valign='top'>Before Reduction</th>
                          <th className='px-2' align='center' valign='top'>After Reduction</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className='px-2' align='left' valign='top'>2 DAYS Tues/Thurs</th>
                          <td className='px-2' align='center' valign='top'>$110.00</td>
                          <td className='px-2' align='center' valign='top'>{' '}$90.00</td>
                        </tr>
                        <tr>
                          <th className='px-2' align='left' valign='top'>3 DAYS Mon/Wed/Fri</th>
                          <td className='px-2' align='center' valign='top'>$180.00</td>
                          <td className='px-2' align='center' valign='top'>$150.00</td>
                        </tr>
                        <tr>
                          <th className='px-2' align='left' valign='top'>5 DAYS Mon-Fri</th>
                          <td className='px-2' align='center' valign='top'>$280.00</td>
                          <td className='px-2' align='center' valign='top'>$230.00</td>
                        </tr>
                      </tbody>
                    </table>
                    <p></p>
                    <br/>
                    <h2 className='section-title'><small><b>WEEKLY SUMMER CAMPS</b><br/><small>Available in July &
                      August </small></small></h2>
                    <p>Please <a style={{ color: '#d2618c' }} href='/contact-us'>contact us</a> for more information</p>

                    <p>
                      Blossom is a part of the government Fee Reduction Initiative and accepts the Affordable
                      Childcare Benefit. Blossom sponsorships available.<br/>
                      <strong><a style={{ color: '#d2618c' }}
                                 href='https://www2.gov.bc.ca/gov/content/family-social-supports/caring-for-young-children/child-care-funding/child-care-benefit'>Click
                        here to apply for the "Affordable Childcare Benefit".</a></strong>
                    </p>
                    <img src={`assets/img/curriculum/circle-time.jpg`} className='sidebar-img img-fluid' alt=''/>
                    {/*
                      <div className="sidebar">
                          <h3 className="sidebar-title">Download Parent Handbook</h3>
                          <ul className="sidebar-list">
                              <li><a href="/assets/Blossom-Progressive-Handbook-2023-Updated.pdf"><i className="fa fa-file-pdf-o" />Handbook.pdf</a></li>
                          </ul>
                          <p>&nbsp;<br/></p>
                          <h3 className="sidebar-title">Download Registration Papers</h3>
                          <ul className="sidebar-list">
                              <li><a href="/assets/Blossom-Progressive-Parent-Agreement-and-Registration-Pkg-2023.pdf"><i className="fa fa-file-pdf-o" />Registration.pdf</a></li>
                          </ul>
                      </div>
                      */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}

        </div>

        {/*====================  End of project details page content  ====================*/}
      </div>
    )
  }
}

export default ProgramsGrid;
