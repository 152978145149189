import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class Contact extends Component{
    render(){
        return(
            <div>
                {/* Navigation bar */}
                <NavBar/>

                {/*====================  content page content ====================*/}
                <div className="page-wrapper section-space--inner--30">
                {/*Contact section start*/}
                <div className="conact-section">
                    <div className="container">
                        {/*}
                    <div className="row section-space--bottom--50">
                        <div className="col">
                            <div className="contact-map">
                                <iframe
                                    title="google-map"
                                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2562.8959441159245!2d-119.3917219!3d50.0320456!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sca!4v1589185936753!5m2!1sen!2sca"
                                    allowFullScreen=""></iframe>
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="row">
                        <div className="col-12">
                            <div className="contact-form">
                                <div className="contact-image">
                                    <img src={`assets/img/logo/logo-text-beside.png`} alt="" />
                                </div>
                                <div className="contact-map">
                                    <iframe
                                        title="google-map"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.8983550435696!2d-119.39159284897052!3d50.03200042520783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537deee7643c1c81%3A0x136b8fc9e1d2a0af!2s10300%20Sherman%20Dr%2C%20Lake%20Country%2C%20BC%20V4V%201Y8!5e0!3m2!1sen!2sca!4v1589275855249!5m2!1sen!2sca"
                                        allowFullScreen=""></iframe>
                                </div>
                                <div className="contact-text">
                                    <p>Blossom is located at Peter Greer Elementary in Partnership with SD 23 since 2005</p>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            <h3>Leave Your Message</h3>
                            <form id="contact-form">
                            <div className="row row-10">
                                <div className="col-md-6 col-12 section-space--bottom--20"><input name="con_name" type="text" placeholder="Your Name" /></div>
                                <div className="col-md-6 col-12 section-space--bottom--20"><input name="con_email" type="email" placeholder="Your Email" /></div>
                                <div className="col-12"><textarea name="con_message" placeholder="Your Message" defaultValue={""} /></div>
                                <div className="col-12"><button>Send Message</button></div>
                            </div>
                            </form>
                        </div>
                        </div>
                        */}
                    </div>
                    </div>
                </div>
                {/*Contact section end*/}
                </div>
                {/*====================  End of content page content  ====================*/}
                
                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Contact;