import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './MobileMenu';
class NavBar extends Component{

    constructor(props){
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);

        this.mobileMenuElement = React.createRef();
    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }
  
    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){


        return(
            <div>
                {/*====================  header area ====================*/}
                <div className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}>
                    <div className="header-area__desktop header-area__desktop--default">
                        {/*=======  header top bar  =======*/}
                        <div className="header-top-bar">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4">
                                        {/* top bar left */}
                                        <div className="top-bar-left-wrapper">
                                            <div className="social-links social-links--white-topbar d-inline-block">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        {/* top bar right */}
                                        <div className="top-bar-right-wrapper">
                                            <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--default d-inline-block">REGISTER</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*=======  End of header top bar  =======*/}
                        {/*=======  header info area  =======*/}
                    <div className="header-info-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <div className="header-info-wrapper align-items-center">
                                    {/* logo */}
                                    <div className="logo">
                                        <Link to = {`${process.env.PUBLIC_URL}/`}>
                                            <img src="assets/img/logo/cherry-blossom-branch.jpg" className="img-fluid" alt="Blossom Progressive Montessori Preschool" />
                                        </Link>
                                    </div>

                                    {/* header tagline for mobile*/}
                                    <div className="header-tagline-mobile">
                                        <div className="header-info-single-item">
                                            <span className="header-info-single-item__quote">
                                                &ldquo;
                                            </span>
                                            <span className="header-info-single-item__content">
                                                <p className="header-info-single-item__tagline">Like seeds nourish children and they shall grow and bloom</p>
                                            </span>
                                            <span className="header-info-single-item__quote">
                                                &rdquo;
                                            </span>
                                        </div>
                                        <div className="header-info-single-item">
                                            <span className="header-info-single-item__content">
                                                <p className="header-info-single-item__tagline__cite">~ Tanya Bunnah</p>
                                            </span>
                                        </div>
                                    </div>

                                    {/* header contact info */}
                                    <div className="header-details">
                                        {/* header tagline */}
                                        <div className="header-tagline">
                                            <div className="header-info-single-item">
                                                <div className="header-info-single-item__quote">
                                                    &ldquo;
                                                </div>
                                                <div className="header-info-single-item__content">
                                                    <p className="header-info-single-item__tagline">Like seeds nourish children and they shall grow and bloom</p>
                                                </div>
                                                <div className="header-info-single-item__quote">
                                                    &rdquo;
                                                </div>
                                                <div className="header-info-single-item__content">
                                                    <p className="header-info-single-item__tagline__cite">~ Tanya Bunnah</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="header-contact-info">
                                            <div className="header-info-single-item">
                                                <div className="header-info-single-item__icon">
                                                <i className="zmdi zmdi-smartphone-android" />
                                                </div>
                                                <div className="header-info-single-item__content">
                                                <h6 className="header-info-single-item__title">Phone</h6>
                                                <p className="header-info-single-item__subtitle">250-808-GROW&nbsp;(4769)</p>
                                                </div>
                                            </div>
                                            <div className="header-info-single-item">
                                                <div className="header-info-single-item__icon">
                                                <i className="zmdi zmdi-home" />
                                                </div>
                                                <div className="header-info-single-item__content">
                                                <h6 className="header-info-single-item__title">Address</h6>
                                                <p className="header-info-single-item__subtitle">10300 Sherman Drive <br />Lake Country, BC V4V 1Y8</p>
                                                </div>
                                            </div>
                                            <div className="header-info-single-item">
                                                <div className="header-info-single-item__icon">
                                                    <i className="zmdi zmdi-email" />
                                                </div>
                                                <div className="header-info-single-item__content">
                                                    <h6 className="header-info-single-item__title">Email</h6>
                                                    <p className="header-info-single-item__subtitle">blossom_montessori@hotmail.com</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    {/* mobile menu */}
                                    <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                                        <i />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    {/*=======  End of header info area =======*/}
                    {/*=======  header navigation area  =======*/}
                    <div className="header-navigation-area default-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* header navigation */}
                                    <div className="header-navigation header-navigation--header-default position-relative">
                                        <div className="header-navigation__nav position-static">
                                        <nav>
                                            <ul>
                                                <li><Link to={`${process.env.PUBLIC_URL}/`}> HOME </Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/contact-us`}>CONTACT</Link> </li>
                                            </ul>
                                        </nav>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/*=======  End of header navigation area =======*/}
                    </div>
                </div>
                {/*====================  End of header area  ====================*/}
                
                {/* Mobile Menu */}
                <MobileMenu ref={this.mobileMenuElement} />

            </div>
        )
    }
}


export default NavBar;