import React, {Component} from 'react';
import NavBar from './components/NavBar';
import ProgramsGrid from './components/ProgramsGrid';
import StaffSlider from './components/StaffSlider';
import Footer from './components/Footer';
import AboutText from "./components/AboutText";
import CurriculumGrid from "./components/CurriculumGrid";
import OurGoals from "./components/OurGoals";
import SecretQuote from "./components/SecretQuote";


class HomeOne extends Component{
    render(){
        
        return(
            <div>
                
                {/* Navigation bar */}
                <NavBar/>

                {/*  */}
                <AboutText />

                {/* Programs Grid */}
                <ProgramsGrid/>

                {/* Curriculum Tab */}
                <CurriculumGrid/>

                {/* Mission */}
                <OurGoals/>

                {/* Secret of Good Teaching Quote */}
                <SecretQuote />
                
                {/* Testimonial Slider */}
                <StaffSlider/>

                {/* Footer */}
                <Footer/>

            </div>
        )
    }
}


export default HomeOne;