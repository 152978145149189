import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


class StaffSlider extends Component{
    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            modules: [EffectFade, Autoplay, Pagination, Navigation]
        }

        let data = [
            {testimonialImage: "miss-tanya.jpg", testimonialName: "Tanya April Bunnah", testimonialDesignation: "Owner/Manager/Directress/Administrator", training: "ECE, Montessori 3-6 years", testimonialText: "Tanya has been in the childcare field since 1995. She has studied in the Bachelor of Social Work program as well as the Child and Youth Care Counselling program. Tanya graduated with an Honorable Mention from the Early Childhood Education Program and was voted class Historian in 2001. She completed her Montessori 3-6 yrs Diploma in 1999. Tanya has taught both locally  and internationally.  She enjoys traveling and brings the world to her classroom."},
            //{testimonialImage: "miss-chili.png", testimonialName: "Chili Tsang", testimonialDesignation: "Assistant", training: "ECEA", testimonialText: "I am a graduate of Hong Kong Baptist University.  I have a certificate in Early Child Development and a Diploma in Early Childhood Education from the Stratford Career Institute.  I have been in the field of Early Childhood Education since 2016. I love to see children grow as they learn. I especially enjoy spending time with my son Henry exploring and going on adventures.  I am thankful for the incredible opportunity to teach at Blossom."},
            {testimonialImage: "miss-shannon.jpg", testimonialName: "Shannon Croom", testimonialDesignation: "Directress", training: "ECEA, Montessori 3-6 years", testimonialText: "Shannon began her journey in Montessori Preschool education in 2005 with the guidance of her esteemed mentor, colleague, and friend Miss Tanya. In 2010, life and circumstances led Shannon along her own path, as she focused on growing her young family. Nevertheless, Shannon has continued to be a strong advocate for quality child care and education through her work as a family daycare provider for the past 13 years. Shannon is delighted to return to Blossom for the 2023/2024 school year and honoured to have the opportunity to once again collaborate with Miss Tanya."},
            //{testimonialImage: "miss-jamie.png", testimonialName: "Jamie Szuuts", testimonialDesignation: "Directress", training: "ECEA, Montessori 3-6 years", testimonialText: "Jamie moved with her family from Winnipeg, MB to Lake Country in 2015. Her love of Montessori began when her son first attended a Montessori preschool. In 2012 she took her Montessori Training while substitute teaching at her children’s preschool and in 2013 she began to work full time.  Jamie is a certified Child Care Assistant in Manitoba, which has transferred to an Early Childhood Education Assistant in BC. In September 2016 Jamie began substituting and teaching music at Blossom and as of September 2017 she teaches full time.  When she is not working, Jamie spends most of her time running her two teenagers around."}
        ];

        const DataList = data.map((val, i)=>{
            return(
                <SwiperSlide className="testimonial-slider__single-slide" key={i}>
                    <div className="author">
                        <div className="author__image">
                            <img src={`assets/img/testimonial/${val.testimonialImage}`} alt="" />
                        </div>
                        <div className="author__details">
                            <h4 className="name">{val.testimonialName}</h4>
                            <div className="designation">{val.testimonialDesignation}</div>
                            <div className="training">{val.training}</div>
                        </div>
                    </div>
                    <div className="content">
                        {val.testimonialText}
                    </div>
                </SwiperSlide>
            )
        });

        return(
            <div>
                {/*====================  testimonial slider area ====================*/}
                <div className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--15 section-space--top--30 section-space--bottom--30">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="testimonial-slider">
                        <div className="testimonial-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of testimonial slider area  ====================*/}

            </div>
        )
    }
}

export default StaffSlider;
