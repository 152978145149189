import React, { Component } from 'react';

class CurriculumGrid extends Component{
    render(){

        
        /* team member data */
        
        let data = [
            {Image: "imaginative-play-750x750.jpg", Title: "Imaginative Play"},
            {Image: "playground2.jpg", Title: "Outdoor Play"},
            {Image: "arts-and-crafts.jpg", Title: "Arts and Crafts"},
            {Image: "japan-sumo-750x750.png", Title: "Culture"},
            {Image: "boy-words-750x750.jpg", Title: "Language"},
            {Image: "math-750x750.jpg", Title: "Math"},
            {Image: "sensorial-750x750.jpg", Title: "Sensorial"},
            {Image: "science-750x750.jpg", Title: "Science"},
            {Image: "practical-life.jpg", Title: "Practical Life"},
            {Image: "courtesy.jpg", Title: "Grace and Courtesy"},
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-lg-3 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="team">
                        <div className="image">
                            <img src={`assets/img/curriculum/${val.Image}`} alt="" />
                        </div>
                        <div className="content">
                            <h3 className="title">{val.Title}</h3>
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div>
                {/*====================  team member area ====================*/}
                <div className="team-member-area section-space--inner--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area text-center">
                            <h2 className="section-title section-space--bottom--50">Curriculum</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-image">
                                <img src={`assets/img/backgrounds/breadcrumb-bg-no-filter.jpg`} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="team-member-wrapper">
                        <div className="row">
                            {/* team member list */}
                            {Datalist}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of team member area  ====================*/}

            </div>
        )
    }
}

export default CurriculumGrid;