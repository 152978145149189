import './App.css';
import {Route, BrowserRouter, Routes} from "react-router-dom";
import HomeBlossom from './HomeBlossom';
import Contact from './pages/Contact';
import NoMatch from './pages/404';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeBlossom />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route component={NoMatch} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
